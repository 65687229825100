import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GstWrapper } from './GST.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'

const Gst = () => 
{
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3002/api/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"gst",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }
return(
 <GstWrapper>
   <Header/>
  <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
           
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                
                  <h4>GST Registration</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">The Goods and Service Tax is the greatest backhanded charge change which mixes in the wide range of various duties into one single expense structure. Under GST Regime, the labor and products are currently burdened under a solitary regulation being Goods and Service Tax Laws. The duties are collected at a solitary rate. The assortment is then bifurcated between both Central and State Government in name of CGST and SGST or IGST.</p>
                <br></br>
                <h4>Threshold Limit for Registration</h4>
                <p className="blackcolor">The enrollment under GST is obligatory for the business substances in light of the standards of turnover or exercises. The business managing in products and those offering support need to compulsorily apply for GST Registration in the event that their total turnover for a monetary year surpasses Rs.40 Lakhs and Rs.20 Lakhs individually. Be that as it may, for business making supplies and offering types of assistance in the North Eastern States, the equivalent is Rs. 20 lakhs and Rs. 10 lakhs individually. Inferable from its advantages, numerous vendors likewise get willful enrollment under GST.</p>
                <p className="blackcolor">The GST enrollment in India is totally an internet based process. GST Registration attests consistent progression of Input Tax Credit as well as giving acknowledgment as an enlisted provider.</p>
                <br></br>
                <h4>Documents Required</h4><br></br>
                  <div className="ticks-list left-text">
                     <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Digital Signature</span> <br></br>
                     <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Photograph</span> <br></br>
                     <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Entity's PAN Card</span><br></br>
                     <span><img src="assets/images/circle.png" style={{width: '12px'}} /> PAN & Aadhar Card</span> <br></br>
                     <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Rent Agreement</span> <br></br>
                     <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Bank details</span><br></br>
                     <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Certificate of Registration</span>
                  </div>
                
                </div>

              </div>
              <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
               <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 <Footer/>
 </GstWrapper>
);
}
Gst.propTypes = {};

Gst.defaultProps = {};

export default Gst;
