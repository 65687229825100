import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PrivateLimitedToPublicWrapper } from './private_limited_to_public.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
const PrivateLimitedToPublic = () => 
{
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3001/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"private limited to public",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }
return(
 <PrivateLimitedToPublicWrapper>
    <Header/>
    <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
           
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                
                  <h4>Private Limited to Public Company</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">Change of Private Company into a Public Company opens another entryway of chances, particularly through gathering pledges and reach of the market. The organization can raise assets through Public Issue and acknowledge stores as well. This construction is suitable for the medium and enormous scope organizations. The transformation will be trailed by the endorsement from Government and change to MoA and AoA.</p>
                <br></br>
                <p className="blackcolor">Further, at least 7 individuals and 3 chiefs are expected for change of Pvt Ltd to Public Ltd. The privileges, liabilities, powers, and commitments continue as before for the organization even after the transformation. Move of offers is conceivable as the limitation on move is eliminated on transformation.</p>
                <br></br>
                <h4>Documents Required</h4><br></br>
                <div className="ticks-list left-text">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> PAN Card</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Identity Proof</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Address Proof</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Photograph</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Business Address Proof</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> NOC form owner</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Rent Agreement</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Incorporation documents</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Financial Statements</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Income Tax Return</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Note: In case of NRI or Foreign National, documents of director(s) must be notarized or apostilled</span><br></br>
                </div>

              </div>
            </div>
            <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
                <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                    <fieldset>
                                        <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                    </fieldset>
                                    <fieldset>
                                        <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                    </fieldset>
                                    <fieldset>
                                        <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                    </fieldset>
                                </div>
                                <div className="col-lg-6">
                                    <fieldset>
                                        <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                    </fieldset>
                                </div>
                                <div className="col-lg-12">
                                    <fieldset>
                                        <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                    </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <Footer/>
 </PrivateLimitedToPublicWrapper>
);
}
PrivateLimitedToPublic.propTypes = {};

PrivateLimitedToPublic.defaultProps = {};

export default PrivateLimitedToPublic;
