import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { EwayBillWrapper } from './eway_bill.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
const EwayBill = () => 
{
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3001/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"eway bill",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }
return(
 <EwayBillWrapper>
   <Header/>
   <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
           
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                 
                  <h4>Generate e-Way Bill</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">
                E-Way Bill is an electronic document, generated online on entrance deployed by the Central Government to help the movement of products. Merely like way bill in erstwhile VAT and CST system, e-way bill system ensures that the bill is submitted for transportation of merchandise. However, under the GST regime, the way bill system is made electronic and centralized. The online centralized system makes the transportation easy for the businesses.
                </p>
                <p className="blackcolor">
                E-Way bill under GST is filed for transportation of products starting with one state then onto the next, i.e. inter-state movement of merchandise and furthermore the intra-state movement. With its first implementation since April, 2018, the system for e-way bill generation and documenting is currently implemented nationwide.
                </p>
                <br></br>

               
                <h4>Documents Required</h4><br></br>
                <div className="ticks-list left-text">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Proof of Applicant</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> TM Certificate</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Power of Attorney</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Copy of TM-A</span><br></br>
                   
                    </div>
             
                </div>

              </div>
              <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
               <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   <Footer/>
 </EwayBillWrapper>
);
}
EwayBill.propTypes = {};

EwayBill.defaultProps = {};

export default EwayBill;
