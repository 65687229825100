import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TrademarkOppositionWrapper } from './Trademark_opposition.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
const TrademarkOpposition = () => 
{
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3001/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"trademark opposition",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }

return(
 <TrademarkOppositionWrapper>
    <Header/>
    <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
           
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                  
                  <h4>Trademark Opposition</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">Trademark Opposition in India comes at a phase after the recorder has supported the trademark application on the uniqueness factor and distributes the trademark in the diary for the outsider opposition. Anybody can go against the distributed trademark inside a time of 90 days which can be reached out for a month more (3+1); starting from the day it was first distributed. Assuming the imprint is against, an opposition continuing is started. After which, both the gatherings included need to reach a resolution and the choice is taken. The choice whether the imprint can be enlisted or deserted would be made. There is no limitation on documenting an opposition. Any individual who accepts that the distributed imprint could make disarray among the general population can petition for the opposition while the onus of protecting the trademark lies in the possession of trademark registrant.</p>
                <br></br>
                <h4>Documents Required</h4><br></br>
                <div className="ticks-list left-text">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Details of Applicant</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Power of Attorney</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Affidavit</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Details about the opposed mark</span> <br></br>
                 
                    </div>
             
                </div>

              </div>
              <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
               <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer/>
 </TrademarkOppositionWrapper>
);
}
TrademarkOpposition.propTypes = {};

TrademarkOpposition.defaultProps = {};

export default TrademarkOpposition;
