import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TrademarkAssingmentWrapper } from './Trademark_assingment.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
const TrademarkAssingment = () => 
{
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3001/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"trademark assingment",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }
return(
 <TrademarkAssingmentWrapper>
    <Header/>
    <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                
                  <h4>Trademark Registration</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">It takes a lot of difficult work and assets to assemble a brand. Thus, It is vital to ensure that you have restrictive possession to utilize the logo, trademark, shape and bundling of products, sound, smell, shading mixes or whatever else that gives your image a particular character. Brand name Act, 1999 permits you to enlist a brand name in India. It clears a path for elite proprietorship freedoms and confines all others from its utilization and along these lines helping the proprietor of the enrolled mark.</p>
                <br></br>
                <p className="blackcolor">Application for logo enrollment should be possible rapidly and on the web. One can begin utilizing a “TM” image when the application is made. Be that as it may, TM enlistment process takes more time to 15-year and a half. It incorporates different cycles for approving unique responsibility for mark. Brand name enlistment in India is going through numerous imaginative changes. Think about this – one can now benefit brand name for Unconventional Marks through which even an unmistakable sound can now be enrolled as a brand name in India.</p>
                <br></br>
                <p className="blackcolor">Specialists at LegalWiz.in record in excess of 1,000 brand name applications consistently. We address clients in all significant urban communities of India including Mumbai, Delhi, Bangalore, Ahmedabad, Chennai and then some.</p>
                <h4>Documents Required</h4><br></br>
                <div className="ticks-list left-text">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Proof of Applicant</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Brand Name & Logo</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> User Affidavit</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Proof of TM Use</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> MSME / Start-up Recognition</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Signed Form TM - 48</span><br></br>
                    </div>
             
                </div>

              </div>
              <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
                <form id="myformbox" action="" method="post">
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required/>
                                </fieldset>
                                <fieldset>
                                    <input type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required=""/>
                                </fieldset>
                                <fieldset>
                                    <input type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required=""/>
                                </fieldset>
                                <fieldset>
                                    <input type="subject" name="subject" id="subject" placeholder="Message" autoComplete="on"/>
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer/>
 </TrademarkAssingmentWrapper>
);
}
TrademarkAssingment.propTypes = {};

TrademarkAssingment.defaultProps = {};

export default TrademarkAssingment;
