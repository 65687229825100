import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SoleProprietorshipWrapper } from './sole_proprietorship.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
const SoleProprietorship = () => 
{
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3001/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"sole proprietorship",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }
return(
 <SoleProprietorshipWrapper>
   <Header />
   <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
           
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                 
                  <h4>Sole Proprietorship Registration</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">Most organizations in India start exclusively without other’s investment. An individual doing business exercises is the sole owner and its business substance is supposed to be a Proprietorship Firm. The personality of an individual and the business are not unique in relation to one another. However, because of the lower charge rate, adaptability and numerous benefits individuals incline toward this design for the beginning phase of business.</p>
              
                <p className="blackcolor">With the incorporation of accomplices, the command over activity lessens. Thus, these owners decide to maintain the business without any assistance and land upon sole proprietorship firm enrollment. In spite of the fact that there is no particular Act to manage this association, there are numerous ways of enlisting a Sole Proprietorship firm. Independent companies expecting to take lower gambles lean toward this construction.</p>
              
                <h2>Documents Required</h2><br></br>
                <div className="ticks-list text-left">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> PAN Card</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Aadhar Card</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Bank Details</span><br></br>
                   
                </div>
             
               </div>

            </div>
            <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
                <form id="contact" action="" method="post">
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                </fieldset>
                                <fieldset>
                                    <input type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                </fieldset>
                                <fieldset>
                                    <input type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
   <Footer />
 </SoleProprietorshipWrapper>
);
}
SoleProprietorship.propTypes = {};

SoleProprietorship.defaultProps = {};

export default SoleProprietorship;
