import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PartnershipFirmWrapper } from './partnership_firm.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'

const PartnershipFirm = () => 
{
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3001/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"partnership firm",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }
return(
 <PartnershipFirmWrapper>
    <Header />
    <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
           
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                 
                  <h4>Sole Proprietorship Registration</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">Partnership firm addresses a business substance that is framed with a reason for creating a gain from the business. At least two gatherings meet up with a conventional understanding (known as Partnership Deed) to claim and deal with the business. The gamble and obligations are shared among the accomplices that shred the weight of a singular accomplice. Additionally, when two meets up, more capital and mastery are joined that assists with arriving at the business goal(s) without any problem.</p>
               
                <p className="blackcolor">Partnership Act, 1932 characterizes the construction of a Partnership firm by giving every one of the fundamental arrangements to run something very similar. The Act approves both enrolled and unregistered partnership firms in India. Nonetheless, an unregistered partnership has not many inadequacies that draw in accomplices towards Partnership Firm Registration. Be that as it may, one can conquer it by enrollment firm whenever after it is framed.</p>
               
                <h2>Documents Required</h2><br></br>
                <div className="ticks-list left-text">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> PAN Card</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Partners Address Proof</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Business Address Proof</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Rent Agreement</span><br></br>
                </div>
             
               </div>

            </div>
            <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
               <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <Footer />
 </PartnershipFirmWrapper>
);
}
PartnershipFirm.propTypes = {};

PartnershipFirm.defaultProps = {};

export default PartnershipFirm;
