import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TrademarkObjectionWrapper } from './Trademark_objection.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
const TrademarkObjection = () => 
{
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3001/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"trademark objection",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }

return(
 <TrademarkObjectionWrapper>
   <Header/>
   <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                 
                  <h4>Trademark Objection Reply</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">Brand name application passes from a few phases, where Examination by Registry is an essential stage. An official would inspect the application and check for any blunders or accuracy of the application affirming to the Trade Marks Act and its Rules.</p>
                <br></br>
                <p className="blackcolor">Upon survey, an assessment report is given with the remarks of the inspector. On the off chance that there is no contention of rules or different brand names, it is passed to distribution in Journal. Nonetheless, on account of confliction, the analyst brings up a criticism and alarms the candidate/lawyer to present his/her answer concerning why the application ought to be passed to next organize. Inability to answer might forsake the application which propels the candidate to document an answer to the assessment report gave by the Registry.</p>
                <br></br>
               
                <h4>Documents Required</h4><br></br>
                <div className="ticks-list left-text">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Mark not Distinguished or Unique</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Deceptively similar Marks</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Mark describing Characteristics of Products</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Descriptive Nature of the Trademark</span><br></br>
                   
                    </div>
             
                </div>

              </div>
              <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
               <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>

   <Footer/>
 </TrademarkObjectionWrapper>
);
}
TrademarkObjection.propTypes = {};

TrademarkObjection.defaultProps = {};

export default TrademarkObjection;
