import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FileDir3KycWrapper } from './file_dir_3_kyc.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'

const FileDir3Kyc = () => {
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3001/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"file dir 3 kyc",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }
return(
 <FileDir3KycWrapper>
   <Header/>
   <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
           
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                 
                  <h4>File DIR-3 KYC</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">
                
                    Filing DIR-3 KYC is a piece of yearly KYC directed by Ministry of Corporate Affairs. MCA has reported obligatory yearly KYC for all DIN holder w.e.f. tenth July 2018 by embedding Rule 12A to Companies (Appointment and Qualifications of Directors) Rules, 2014. This consistence is pertinent to each individual holding DIN with ‘Endorsed’ status and furthermore to the people who are excluded.
                </p>
                <p className="blackcolor">
                    This being yearly necessity, each DIN holder needs to satisfy the consistence concerning each monetary year. The normal due date for recording is 30th April of prompt next monetary year. The last date (for example fifteenth September, 2018) for documenting the structure for an individual holding DIN at the very latest 31st March, 2018 has been passed. Hence, DIN of the individual who has neglected to document the structure will be deactivated. To reactivate the DIN, the concerned individual needs to document the KYC with expense of INR 5,000 to Government.
                </p>
                <p className="blackcolor">  
                    Notwithstanding, considering the specialized challenges looked to consent to KYC necessities, the service has permitted DIR-3 KYC documenting at a diminished charge of Rs 500 for 15 days beginning from 21st September. October 6 onwards, expense of Rs 5,000 will apply to reactivate DIN.
                </p>
               
                <h2>Documents Required</h2><br></br>
                <div className="ticks-list left-text">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Digital Signature Certificate</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> PAN Card</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Address Proof</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Photograph</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Mobile No. & E-mail Id</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Other Details</span> <br></br>
                </div>
             
               </div>

            </div>
            <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
               <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
   <Footer/>
 </FileDir3KycWrapper>
);
}
FileDir3Kyc.propTypes = {};

FileDir3Kyc.defaultProps = {};

export default FileDir3Kyc;
