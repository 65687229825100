import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FileInc22AWrapper } from './file_inc_22a.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
const FileInc22A = () => {
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3001/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"file inc 22a",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }

return(
 <FileInc22AWrapper>
    <Header />
    <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
           
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                
                  <h4>File INC - 22A</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">
                    MCA has introduced a new form INC-22A – ACTIVE(Active Company Tagging Identities and Verification) with effect from 25th February 2019 by inserting Rule 25 A to the Companies (Incorporation) Rules, 2014. Every company incorporated on or before 31st December 2017 and are under ‘Active’ status has to file this form on or before 25th April 2019. If the form is not filed within the due date then the MCA will charge late filing fees of Rs. 10,000/-. Further, the company will be marked as ‘ACTIVE non-complaint company’ and the DIN associated with such company will be marked as ‘Director of ACTIVE non-compliant company’.
                </p>
               
                <h2>Documents Required</h2><br></br>
                <div className="ticks-list left-text">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Certificate of Incorporation</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Photograph of external building of the registered office</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Photograph of inside the office</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Latitude and longitude of the registered office</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Email id of the company</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Statutory Auditor</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Annual compliance forms</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> DSC & Other details</span><br></br>
             
                </div>
             
               </div>

            </div>
            <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
               <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <Footer />
 </FileInc22AWrapper>
);
}
FileInc22A.propTypes = {};

FileInc22A.defaultProps = {};

export default FileInc22A;
