import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FssaiWrapper } from './FSSAI.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'

const Fssai = () => 
{
    const [name,setName] = useState()
    const [email,setEmail] = useState()
    const [phone,setPhone] = useState()
    const [message,setMessage] = useState()
  
    const navigate = useNavigate()
    function submit()
    {
        const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3001/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"fssai",
      message:message,
      created_at:formattedTime
    })
      alert('Submitted Successfully')
     // navigate('/accounting-bookkeeping')
    }
return(
 <FssaiWrapper>
   <Header />
   <div id="about" className="about section">
      <div className="container">
         <div className="row">
            <div className="col-lg-12">
               <div className="row">
                  <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                     <div className="about-right-content">
                        <div className="section-heading">
                           <h4>FSSAI Registration</h4>
                           <div className="line-dec"></div>
                        </div>
                        <p className="blackcolor">FSSAI is a condensing for the Food Safety and Standards Authority of India. FSSAI is an autonomous body perceived under the Ministry of Health and Family Welfare, Government of India that deals with the food permit enrollment. This association works for getting the Laws and Standards for Food Business in India. The fundamental point of FSSAI is to manage and check for safe food assembling, stockpiling, and dealing with rehearses by Food Business Operators (FBOs). A FSSAI enrollment declaration accompanies a 14 digit License number to FBOs which is expected to be imprinted on all the food items with FSSAI logo. The permit can be applied for a base 1 year to greatest 5 years; it can again be reestablished or re-applied for according to rules expressed under Food Safety and Standards Act, 2006.</p>
                        <br></br>
                        <h4>Documents Required</h4><br></br>
                        <div className="ticks-list left-text">
                              <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Declaration Form</span> <br></br>
                              <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Authority Letter</span> <br></br>
                              <span><img src="assets/images/circle.png" style={{width: '12px'}} /> 3 Passport Size Photographs - (Proprietor / Partner / Director)</span><br></br>
                              <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Photo Identity of FBO</span> <br></br>
                              <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Proof of possessiob of Place of Business such as Rent Agreement, NOC etc.</span> <br></br>
                              <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Electricity Bill or Water Bill of the business premises</span><br></br>
                              <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Property Tax Bill</span><br></br>
                              <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Product List to be dealth with</span><br></br>
                              <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Plan stating Food Safety Management System</span><br></br>
                              <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Form-A Completed and Signed</span><br></br>
                              <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Constitution of Business such as Incorporation Certificate, Partnership Deed etc.</span><br></br>
                        </div>
                     </div>
                  </div>
              <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
               <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>

<Footer />
 </FssaiWrapper>
);
}
Fssai.propTypes = {};

Fssai.defaultProps = {};

export default Fssai;
