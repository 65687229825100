import React from 'react';
import PropTypes from 'prop-types';
import { HeaderWrapper } from './Header.styled';

const Header = () => (
 <HeaderWrapper>
     
  <div className="pre-header">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 col-sm-8 col-7">
          <ul className="info">
            <li><a href="#"><img src="assets/images/gmail.png" style={{ height: '25px', width: '25px'}} /> corpfillings@gmail.com</a></li>
            <li><a href="#"><img src="assets/images/viber.png" style={{ height: '25px',width: '25px'}} /> 8817401900</a></li>
          </ul>
        </div>
        <div className="col-lg-4 col-sm-4 col-5">
          <ul className="social-media">
            <li><a href="#"><img src="assets/images/facebook.png" /></a></li>
            <li><a href="#"><img src="assets/images/google.png" /></a></li>
            <li><a href="#"><img src="assets/images/twitter.png" /></a></li>
            <li><a href="#"><img src="assets/images/linkedin.png" /></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
 
  <header className="header-area header-sticky wow slideInDown" data-wow-duration="0.75s" data-wow-delay="0s">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <nav className="main-nav">
          
            <a href="/" className="logo">
              <span style={{fontSize: 'xxx-large',fontFamily: 'auto',fontWeight: 'bolder'}}>Corpfillings</span>
             
            </a>
           
            <ul className="nav">
              <li className="scroll-to-section"><a href="/" className="active">Home</a></li>
              <li className="scroll-to-section"><a href="#about">About</a></li>
              <li className="scroll-to-section"><a href="#services">Services</a></li>
             
              <li className="scroll-to-section"><a href="#contact">Contact</a></li> 
           
            </ul>        
            <a className='menu-trigger'>
                <span>Menu</span>
            </a>
          
          </nav>
        </div>
      </div>
    </div>
  </header>
 </HeaderWrapper>
);

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
