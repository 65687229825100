import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AnnualFilingWrapper } from './annual_filing.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'

const AnnualFiling = () => {
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3001/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"annual filing",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }



return(
 <AnnualFilingWrapper>
   <Header/>
   <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
           
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                 
                  <h4>Annual Filing</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">
                    A Limited Liability Partnership partakes in a different status. Consequently, an association needs to keep up with its dynamic status by routinely recording with the Ministry of Corporate Affairs (MCA). Yearly consistence recording is required for any LLP, regardless of whether having a business. Yearly consistence for LLP requires recording two separate structures. One of which is for Annual Return, while another is for Statement of Accounts and Solvency.
                </p>
                <p className="blackcolor">
                    The structures are petitioned for announcing the exercises and monetary information for each monetary year in the impending year. The inability to satisfy LLP Annual Compliance prerequisites collects an extra expense of ₹ 100 every day of a deferral till the real date of documenting. Henceforth, aside from the order, the weighty punishment urges the Designated Partners to satisfy the prerequisites.
                    
                </p>
              
               
                <h2>Documents Required</h2><br></br>
                <div className="ticks-list left-text">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> PAN Card & COI</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> LLP Agreement</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Financial Statements</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Digital Signature</span> <br></br>

                </div>
             
               </div>

            </div>
            <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
               <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
   <Footer/>
 </AnnualFilingWrapper>
);
}
AnnualFiling.propTypes = {};

AnnualFiling.defaultProps = {};

export default AnnualFiling;
