import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AccountingBookkeepingWrapper } from './accounting_bookkeeping.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
const AccountingBookkeeping = () => {
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  
    axios.post('http://13.126.231.122:3002/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"account bookkeeping",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }


return(
 <AccountingBookkeepingWrapper>
   <Header/>
     <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
           
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                 
                  <h4>Accounting and Bookkeeping</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">
                
                    Recording the everyday monetary issues of a business is a piece of Bookkeeping. The arrangement of monetary records is known as Accounting. Bookkeeping is obligatory for all types of organizations including Small Enterprises and Freelancers. Aside from the impulse, it is generally better to stay up with the latest and exact to keep away from monetary tangles. Upkeep of records is as fundamental for a grand slam business for what it’s worth for an industrialist. There are required norms of bookkeeping a business needs to follow. To adjust to such models, it becomes fundamental for take help from specialists.
                </p>
               
                <h2>Documents Required</h2><br></br>
                <div className="ticks-list left-text">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Company / Business Incorporation Documents</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Bank statement of a financial year or monthly statement (with remarks)</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Purchase-Sales invoices, if any</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Expense bills, if any</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Any receivable and payable detail</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Any other Government registration taken</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Cash Expenses</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Bank statement of partner/ members with remarks if used for business transactions</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Expenses made for company or LLP registration by promoters</span> <br></br>
                </div>
             
               </div>

            </div>
            <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
                <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
 </AccountingBookkeepingWrapper>
);
}
AccountingBookkeeping.propTypes = {};

AccountingBookkeeping.defaultProps = {};

export default AccountingBookkeeping;
