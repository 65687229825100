import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NidhiCompanyWrapper } from './nidhi_company.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
const NidhiCompany = () => 
{
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3001/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"nidhi company",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }
return(
 <NidhiCompanyWrapper>
    <Header />
    <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
           
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                 
                  <h4>Nidhi Company Registration</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">
                    Nidhi Companies have a place with the Non-banking monetary companies structure. Enlisting a Nidhi Company permits a Nidhi to acquire from its individuals and loan to the individuals.
                </p>
                <p className="blackcolor">
                Nidhi Companies are enlisted in India are made to develop the propensity for frugality and investment funds among its individuals. The assets that are added to a Nidhi Company are just from its individuals.
                </p>
                <p className="blackcolor">
                For Incorporating a Nidhi Company, no permit is expected from the Reserve Bank of India. Consequently, the arrangement of the Nidhi Company is simple. Nidhi Companies are enlisted as Public Companies and ought to have Nidhi Limited at the remainder of the name.
                </p>
                <p className="blackcolor">
                It ought to likewise be noticed that the Nidhi Companies fall under the domain of the Reserve Bank of India as the working of Nidhi Companies is like NBFCs.</p>
                                    
                <h2>Documents Required</h2><br></br>
                <div className="ticks-list left-text">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> PAN Card</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Passport size photograph</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> ID proof</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Rent Agreement</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Address proof</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Registered office proof</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> NOC from the owner</span><br></br>
                </div>
             
               </div>

            </div>
            <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
               <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <Footer />
 </NidhiCompanyWrapper>
);
}
NidhiCompany.propTypes = {};

NidhiCompany.defaultProps = {};

export default NidhiCompany;
