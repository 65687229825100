import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FileInc20AWrapper } from './file_inc_20a.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
const FileInc20A = () => {
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3001/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"file inc 20a",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }

return(
 <FileInc20AWrapper>
    <Header />
    <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
           
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                 
                  <h4>File INC - 20A</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">
                
                    INC-20A is a required structure that will be documented by an organization joined on or after 02/11/2018 with MCA. It is otherwise called Declaration of Commencement of Business. It ought to be documented by the directors in the span of 180 days from date of consolidation of an organization which has share capital. When the organization gets an endorsement of consolidation, the directors will document revelation testament of beginning of business. It is compulsory to open a current record in a bank for storing the offer capital bought in MOA by every single advertiser.
                
                </p>
               
                <h2>Documents Required</h2><br></br>
                <div className="ticks-list">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Proof of payment of subscribed capital</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Registration Certificate</span> <br></br>

             
                </div>
             
               </div>

            </div>
            <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
               <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <Footer />
 </FileInc20AWrapper>
);
}
FileInc20A.propTypes = {};

FileInc20A.defaultProps = {};

export default FileInc20A;
