import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LlpToPrivateLimitedWrapper } from './llp_to_private_limited.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
const LlpToPrivateLimited = () => 
{
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3001/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"llp to private limited",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }

return(
 <LlpToPrivateLimitedWrapper>
   <Header />
   <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
           
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                
                  <h4>LLP to Private Limited</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">In India, private companies are one of the most widely recognized types of business structure. They give higher possibilities of development, improvement and are best for raising value capital which is unimaginable 
                    if there should arise an occurrence of LLP. LLP structure isn’t reasonable assuming the proprietors require financial speculator or private value financial backers to put resources into their organization as they would decide to put resources into a private restricted organization and not an association or LLP. The second justification behind change is the that the FDI if there should arise an occurrence of a private restricted organization requires no endorsement it very well may be done straightforwardly dissimilar to in a LLP. Particularly on the off chance that the advertisers or proprietors of the organization are NRI’s or an outsider fusing a private restricted organization is a best decision over a LLP. Consequently change is obligatory assuming the previously mentioned necessities should be satisfied.</p>
                <br></br>
                
                <h4>Documents Required</h4><br></br>
                <div className="ticks-list left-text">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> PAN Card</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Identity Proof</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Address Proof</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Photograph</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Business Address Proof</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> NOC form owner</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Rent Agreement</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Copy of ITR</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Note: In case of NRI or Foreign National, documents of director(s) must be notarized or apostilled</span> <br></br>
                </div>
                </div>

              </div>
              <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
               <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 
   <Footer />
 </LlpToPrivateLimitedWrapper>
);
}
LlpToPrivateLimited.propTypes = {};

LlpToPrivateLimited.defaultProps = {};

export default LlpToPrivateLimited;
