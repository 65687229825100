import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PrivateLimitedToLlpWrapper } from './private_limited_to_llp.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
const PrivateLimitedToLlp = () => 
{
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3001/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"private limited to llp",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }
return(
 <PrivateLimitedToLlpWrapper>
    <Header/>
    <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
    
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                 
                  <h4>Private Limited to LLP</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">LLP structure offers the advantages of both – an organization and an organization. In this manner, by changing the organization over to LLP, the business acquires functional adaptability. The attributes of being a different lawful substance and restricted risk continue as before. Further, the activities and the board come straightforwardly in hands of the LLP partner(s) dissimilar to in Private Company where it is with the director(s).</p>
                <br></br>
                <p className="blackcolor">LLP is represented by the LLP Agreement executed by the Partners. It has lesser consistence prerequisites and it is more straightforward to work. It protects the advantages of an organization with giving security and believability to an organization. The transformation of Private restricted to LLP should be trailed by the endorsement of the Ministry by the internet based application giving fundamental archives.</p>
                <br></br>
                <h4>Documents Required</h4><br></br>
                <div className="ticks-list left-text">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> NOC</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Clearance from tax authorities</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Creditors approval</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Financial Statements</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Digital signature</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Pvt. Ltd. company documents</span><br></br>
                </div>
                </div>

              </div>
              <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
               <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
 </PrivateLimitedToLlpWrapper>
);
}
PrivateLimitedToLlp.propTypes = {};

PrivateLimitedToLlp.defaultProps = {};

export default PrivateLimitedToLlp;
