import React from 'react';

import { HomeWrapper } from './Home.styled';
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

import { useNavigate } from 'react-router-dom';
const Home = () => {
   const navigate = useNavigate()

   return(<HomeWrapper>


  {/* <div id="js-preloader" className="js-preloader">
    <div className="preloader-inner">
      <span className="dot"></span>
      <div className="dots">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div> */}
   <Header></Header>
  

  <div className="main-banner wow fadeIn" id="top" data-wow-duration="1s" data-wow-delay="0.5s">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-12 align-self-center">
              <div className="left-content show-up header-text wow fadeInLeft" data-wow-duration="1s" data-wow-delay="1s">
                <div className="row">
                  <div className="col-lg-12 text-center">
                 
                    <h3>Start a Company, IP registration, Tax registration & Filings</h3>
                    <br></br>
                    <h6>Starting & Managing Your
                      Business has never been easier!</h6>
                  </div>
                  <br></br>
                  <div className="col-lg-12 text-center">
                    <div className="border-first-button scroll-to-section">
                      <a href="#contact">Free Quote</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-left-image  wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">
                <img src="assets/images/about-dec-v3.png" alt="" />
              </div>
            </div>
            <div className="col-lg-6 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
               
                  <h4>Company Registration</h4>
                  <div className="line-dec"></div>
                </div>
                <p>We offer fast and reasonable support to enlist a private limited company through 100 percent online interaction. Our administrations are trusted by great many organizations in all significant urban areas of India. Pvt Ltd Company Registration is profoundly liked by new businesses.</p>
                <div className="row">
                  <div className="col-lg-4 col-sm-4">
                    <div className="skill-item first-skill-item wow fadeIn" data-wow-duration="1s" data-wow-delay="0s">
                      <div className="progress" data-percentage="100">
                        <span className="progress-left">
                          <span className="progress-bar"></span>
                        </span>
                        <span className="progress-right">
                          <span className="progress-bar"></span>
                        </span>
                        <div className="progress-value">
                          <div>
                            <span>Private Limited</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4">
                    <div className="skill-item second-skill-item wow fadeIn" data-wow-duration="1s" data-wow-delay="0s">
                      <div className="progress" data-percentage="100">
                        <span className="progress-left">
                          <span className="progress-bar"></span>
                        </span>
                        <span className="progress-right">
                          <span className="progress-bar"></span>
                        </span>
                        <div className="progress-value">
                          <div>
                            <span>Limited</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4">
                    <div className="skill-item third-skill-item wow fadeIn" data-wow-duration="1s" data-wow-delay="0s">
                      <div className="progress" data-percentage="100">
                        <span className="progress-left">
                          <span className="progress-bar"></span>
                        </span>
                        <span className="progress-right">
                          <span className="progress-bar"></span>
                        </span>
                        <div className="progress-value">
                          <div>
                            <span>Proprietorship</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="services" className="services section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-heading  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
            <h6>Our Services</h6>
            <h4>What Our Agency <em>Provides</em></h4>
            <div className="line-dec"></div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="naccs">
            <div className="grid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="menu">
                    <div className="first-thumb active">
                      <div className="thumb">
                        <span className="icon"><img src="assets/images/service-icon-01.png" alt="" /></span>
                         Registration
                      </div>
                    </div>
                    <div>
                      <div className="thumb">                 
                        <span className="icon"><img src="assets/images/service-icon-02.png" alt="" /></span>
                        Intellectual Property
                      </div>
                    </div>
                    <div>
                      <div className="thumb">                 
                        <span className="icon"><img src="assets/images/service-icon-03.png" alt="" /></span>
                        Change in Business
                      </div>
                    </div>
                    <div>
                      <div className="thumb">                 
                        <span className="icon"><img src="assets/images/service-icon-04.png" alt="" /></span>
                        Business Registration
                      </div>
                    </div>
                    <div className="last-thumb">
                      <div className="thumb">                 
                        <span className="icon"><img src="assets/images/service-icon-01.png" alt="" /></span>
                        Accounting & Compliance
                      </div>
                    </div>
                    <div className="last-thumb">
                      <div className="thumb">                 
                        <span className="icon"><img src="assets/images/service-icon-02.png" alt="" /></span>
                       Tax Filing
                      </div>
                    </div>
                  </div>
                </div> 
                <div className="col-lg-12">
                  <ul className="nacc">
                    <li className="active">
                      <div>
                        <div className="thumb">
                          <div className="row">
                            <div className="col-lg-6 align-self-center">
                              <div className="left-text">
                                <h4>Registration</h4>
                        
                                <div className="ticks-list">
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/gst-registration')} >GST Registration</a></span> 
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/startup-india-registration')}>Startup India Registration</a></span> 
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/pf-registration')}>PF Registration</a></span>
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/pan-application')}>PAN Application</a></span> 
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/tan-application')}>TAN Application</a></span> 
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/fssai-registration')}>FSSAI Registration</a></span></div>
                             
                              </div>
                            </div>
                            <div className="col-lg-6 align-self-center">
                              <div className="right-image">
                                <img src="assets/images/services-image.jpg" alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div>
                        <div className="thumb">
                          <div className="row">
                            <div className="col-lg-6 align-self-center">
                              <div className="left-text">
                                <h4>Intellectual Property</h4>
                               
                                <div className="ticks-list">
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/trademark-registration')}>Trademark Registration</a></span> 
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/trademark-objection')}>Trademark Objection Reply</a></span> 
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/trademark-opposition')}>Trademark Opposition</a></span>
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/trademark-assingment')}>Trademark Assingment</a></span> 
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/trademark-renewal')}>Trademark Renewal</a></span> 
                                </div>
                              
                              </div>
                            </div>
                            <div className="col-lg-6 align-self-center">
                              <div className="right-image">
                                <img src="assets/images/services-image-02.jpg" alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div>
                        <div className="thumb">
                          <div className="row">
                            <div className="col-lg-6 align-self-center">
                              <div className="left-text">
                                <h4>Change in Business</h4>
                                
                                <div className="ticks-list">
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/proprietorship-to-opc')}>Proprietorship to OPC</a></span> 
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/partnership-to-llp')}>Partnership to LLP</a></span> 
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/llp-to-private-limited')}>LLP to Private Limited</a></span>
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/partnership-to-private-limited')}>Partnership to Private Limited</a></span> 
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/private-limited-to-llp')}>Private Limited to LLP</a></span> 
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/private-limited-to-public')}>Private Limited to Public</a></span></div>
                             
                              </div>
                            </div>
                            <div className="col-lg-6 align-self-center">
                              <div className="right-image">
                                <img src="assets/images/services-image-03.jpg" alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div>
                        <div className="thumb">
                          <div className="row">
                            <div className="col-lg-6 align-self-center">
                              <div className="left-text">
                                <h4>Business Registration</h4>
                               
                                <div className="ticks-list">
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/private-limited-registration')}>Private Limited</a></span> 
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/opc-registration')}>OPC Registration</a></span> 
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/section-8-company-registration')}>Section 8 Company</a></span>
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/producer-company-registration')}>Producer Company</a></span> 
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/nidhi-company-registration')}>Nidhi Company</a></span> 
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/micro-finance-registration')}>Micro Finance</a></span>
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/llp-company-registration')}>LLP Company</a></span>
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/partnership-firm-registration')}>Partnership Firm</a></span>
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/sole-proprietorship-registration')}>Sole Proprietorship</a></span></div>
                                
                              </div>
                            </div>
                            <div className="col-lg-6 align-self-center">
                              <div className="right-image">
                                <img src="assets/images/services-image-04.jpg" alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div>
                        <div className="thumb">
                          <div className="row">
                            <div className="col-lg-6 align-self-center">
                              <div className="left-text">
                                <h4>Accounting & Compliance</h4>
                               
                                <div className="ticks-list">
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/annual-compliances')}>Annual Compliances</a></span> 
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/annual-filing')}>Annual Filing</a></span> 
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/file-dir-3-kyc')}>File DIR-3 KYC</a></span>
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/file-inc-20a')}>File INC-20A</a></span> 
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/file-inc-22a')}>File INC-22A</a></span> 
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/accounting-bookkeeping')}>Accounting & Bookkeeping</a></span></div>
                               
                              </div>
                            </div>
                            <div className="col-lg-6 align-self-center">
                              <div className="right-image">
                                <img src="assets/images/services-image-04.jpg" alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div>
                        <div className="thumb">
                          <div className="row">
                            <div className="col-lg-6 align-self-center">
                              <div className="left-text">
                                <h4>Tax Filing</h4>
                              
                                <div className="ticks-list">
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/gst-return-filing')}> GST Return Filing</a></span> 
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/itr-filing')}> ITR Filing</a></span> 
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/e-way-bill')}> e-Way Bill</a></span>
                                  <span><img src="assets/images/circle.png" style={{width: '12px'}} /> <a onClick={() => navigate('/tds-return-filing')}> TDS Return Filing</a></span> 
                                  </div>
                               
                              </div>
                            </div>
                            <div className="col-lg-6 align-self-center">
                              <div className="right-image">
                                <img src="assets/images/services-image.jpg" alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>          
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  
  <div id="free-quote" className="free-quote">
    <div className="container">
      <div className="row">
        <div className="col-lg-4 offset-lg-4">
          <div className="section-heading  wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s">
            <h6>Get Your Free Quote</h6>
            <h4>Grow With Us Now</h4>
            <div className="line-dec"></div>
          </div>
        </div>
        <div className="col-lg-8 offset-lg-2  wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
          <form id="search" action="#" method="GET">
            <div className="row">
              <div className="col-lg-4 col-sm-4">
                <fieldset>
                  <input type="web" name="web" className="website" placeholder="Your website URL..." autoComplete="on" required />
                </fieldset>
              </div>
              <div className="col-lg-4 col-sm-4">
                <fieldset>
                  <input type="address" name="address" className="email" placeholder="Email Address..." autoComplete="on" required />
                </fieldset>
              </div>
              <div className="col-lg-4 col-sm-4">
                <fieldset>
                  <button type="submit" className="main-button">Get Quote Now</button>
                </fieldset>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>


  <div id="portfolio" className="our-portfolio section">
    <div className="container">
      <div className="row">
        <div className="col-lg-5">
          <div className="section-heading wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s">
            
            <h4>Our <em>Services</em></h4>
            <div className="line-dec"></div>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid wow fadeIn" data-wow-duration="1s" data-wow-delay="0.7s">
      <div className="row">
        <div className="col-lg-12">
          <div className="loop owl-carousel">
            <div className="item">
              <a href="#">
                <div className="portfolio-item">
                <div className="thumb">
                  <img src="assets/images/business.jpeg" alt="" />
                </div>
                <div className="down-content">
                  <h4>Business Registration</h4>
                  
                </div>
              </div>
              </a>  
            </div>
            <div className="item">
              <a href="#">
                <div className="portfolio-item">
                <div className="thumb">
                  <img src="assets/images/trademark.jpg" alt="" />
                </div>
                <div className="down-content">
                  <h4>Trademark Registration</h4>
                 
                </div>
              </div>
              </a>  
            </div>
            <div className="item">
              <a href="#">
                <div className="portfolio-item">
                <div className="thumb">
                  <img src="assets/images/gst.jpg" alt="" />
                </div>
                <div className="down-content">
                  <h4>GST Registration</h4>
                  
                </div>
              </div>
              </a>  
            </div>
            <div className="item">
              <a href="#">
                <div className="portfolio-item">
                <div className="thumb">
                  <img src="assets/images/itr.jpg" alt="" />
                </div>
                <div className="down-content">
                  <h4>ITR Filing</h4>
                 
                </div>
              </div>
              </a>  
            </div>
            <div className="item">
              <a href="#">
                <div className="portfolio-item">
                <div className="thumb">
                  <img src="assets/images/gstreturn.jpg" alt="" />
                </div>
                <div className="down-content">
                  <h4>GST Return Filing</h4>
                 
                </div>
              </div>
              </a>  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div id="contact" className="contact-us section">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <div className="section-heading wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
            <h6>Contact Us</h6>
            <h4>Get In Touch With Us <em>Now</em></h4>
            <div className="line-dec"></div>
          </div>
        </div>
        <div className="col-lg-12 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
          <form id="contact" action="" method="post">
            <div className="row">
              <div className="col-lg-12">
                <div className="contact-dec">
                  <img src="assets/images/contact-dec-v3.png" alt="" />
                </div>
              </div>
            
              <div className="col-lg-12">
                <div className="fill-form">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="info-post">
                        <div className="icon">
                          <img src="assets/images/phone-icon.png" alt="" />
                          <a href="#">+91-8817401900</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="info-post">
                        <div className="icon">
                          <img src="assets/images/email-icon.png" alt="" />
                          <a href="#">corpfillings@gmail.com</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="info-post">
                        <div className="icon">
                          <img src="assets/images/location-icon.png" alt="" />
                          <a href="#">J.P. Nagar Bangalore</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <fieldset>
                        <input type="name" name="name" id="name" placeholder="Name" autoComplete="on" required />
                      </fieldset>
                      <fieldset>
                        <input type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Your Email" required="" />
                      </fieldset>
                      <fieldset>
                        <input type="subject" name="subject" id="subject" placeholder="Subject" autoComplete="on" />
                      </fieldset>
                    </div>
                    <div className="col-lg-6">
                      <fieldset>
                        <textarea name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                      </fieldset>
                    </div>
                    <div className="col-lg-12">
                      <fieldset>
                        <button type="submit" id="form-submit" className="main-button ">Send Message Now</button>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <Footer />

   </HomeWrapper>)
}

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
