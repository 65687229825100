import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MicroFinanceWrapper } from './micro_finance.styled';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const MicroFinance = () => {
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3001/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"micro finance",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }

return(
 <MicroFinanceWrapper>
    <Header />
    <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
           
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                 
                  <h4>Micro Finance Company Registration</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">A microfinance company is essentially a sort of monetary organization which offers limited scope monetary types of assistance as advance, credit or investment funds. These organizations are acquainted with facilitate the credit framework for independent companies as they don’t get an advance from banks because of their intricate interaction. Accordingly it is ordinarily named as a Micro-credit association. They offer little advances to different independent companies or families that don’t approach formal financial channels or are not qualified for advances. They give little advances adding up to under Rs.50,000 in sum to individuals who live in country regions and for individuals who live in metropolitan regions, the credit sum limit is Rs.1,25,000. The most straightforward method for enlisting a Micro Finance Company in India is to enlist a Section-8 Company with MCA (Ministry of Corporate Affairs), without the charge of any sort of minor cash or assurance security. A microfinance company can give advances at economical rates coordinated by the RBI and focal government. They offer colossal help to all the country and horticultural advancement including the production of both pay and work. There are essentially 2 kinds of microfinance organizations that are permitted in India, the previous sort must be enrolled with the RBI and the last option is the non-benefit type, which is enlisted as a part 8 company and needn’t bother with RBI endorsement.</p>
                 <h2>Documents Required</h2><br></br>
                <div className="ticks-list left-text">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> PAN Card</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Passport size photograph</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> ID proof</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Rent Agreement</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Address proof</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Registered office proof</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> NOC from the owner</span><br></br>
                </div>
             
               </div>

            </div>
            <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
               <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <Footer />
 </MicroFinanceWrapper>
);
}
MicroFinance.propTypes = {};

MicroFinance.defaultProps = {};

export default MicroFinance;
