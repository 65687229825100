import React from 'react';
import PropTypes from 'prop-types';
import { FooterWrapper } from './Footer.styled';

const Footer = () => (
 <FooterWrapper>
     <footer>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <p>Copyright © 2022 Corpfillings. All Rights Reserved. 
        
        </p>
        </div>
      </div>
    </div>
  </footer>
 </FooterWrapper>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
