import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GetReturnFilingWrapper } from './get_return_filing.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
const GetReturnFiling = () => 
{
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3001/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"gst return filing",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }

return(
 <GetReturnFilingWrapper>
    <Header/>
    <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
           
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                 
                  <h4>GST Return Filing</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">
                Goods and Service Tax regulation presently oversees the significant part of the aberrant duty framework in India. The providers enlisted under this system need to satisfy the consistence prerequisite routinely. In light of the turnover and the exercises, the prerequisites and time of recording returns are recommended under regulation.
                </p>
                <p className="blackcolor">
                With online GST return recording, the citizens private the Goods and Service Tax Network (GSTN) about the inflow and surge of provisions including how much expense paid and gathered. While recording the available exchanges with Government, the citizen is additionally expected to pay how much assessment gathered from an outward provider of goods or services after the derivation of Input Tax Credit (ITC).
                </p>
                <br></br>

               
                <h4>Documents Required</h4><br></br>
                <div className="ticks-list left-text">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Digital Signature</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Certificate</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Log-in credentials</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Details of Supplies</span><br></br>
                   
                    </div>
             
                </div>

              </div>
              <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
               <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
 </GetReturnFilingWrapper>
);
}
GetReturnFiling.propTypes = {};

GetReturnFiling.defaultProps = {};

export default GetReturnFiling;
