import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ItrFilingWrapper } from './itr_filing.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
const ItrFiling = () => 
{
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3001/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"itrn filing",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }
return(
 <ItrFilingWrapper>
   <Header/>
    <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
           
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                 
                  <h4>ITR Filing</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">
                The pay procured by the people and organizations are likely to burden. The duty demanded on the pay of an individual is Income charge, which is exacted and gathered by the Central Government. Such assessment on pay procured is payable in a similar monetary year wherein it is accumulated as advance expense. In any case, the suggestion and computation of the pay also the duty responsibility is given in the Assessment Year. This insinuation structure is called Income Tax Return. The structure and time limit for documenting ITR are different for various citizens in view of the models.
                </p>
                <p className="blackcolor">
                Personal Tax Return structures are as of late changed, to work on the client experience. However, through these new or adjusted plans, it is obvious that citizens are dependable to demonstrate a case of costs, exclusions and derivations. Thus, internet recording of personal government form needs support from experienced experts to ensure the right documenting of your return.
                </p>
                <br></br>

               
                <h4>Documents Required</h4><br></br>
                <div className="ticks-list left-text">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> PAN Card</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Entities PAN Card</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Aadhar Card</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Cancelled Cheque</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Bank Account Statement</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Financial Statements</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Investment/ expenses u/s 80</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Form 16</span><br></br>
                    </div>
             
                </div>

              </div>
              <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
               <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
 </ItrFilingWrapper>
);
}
ItrFiling.propTypes = {};

ItrFiling.defaultProps = {};

export default ItrFiling;
