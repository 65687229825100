import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PrivateLimitedWrapper } from './private_limited.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
const PrivateLimited = () => 
{
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3001/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"private limited",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }
return(
 <PrivateLimitedWrapper>
    <Header />
    
  <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
           
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                
                  <h4>Private Limited Registration</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">
                
                    We offer fast and reasonable support to enlist a private limited company through 100 percent online interaction. Our administrations are trusted by great many organizations in all significant urban areas of India. Pvt Ltd Company Registration is profoundly liked by new businesses.
                </p>
                <p className="blackcolor">
                    Company is a famous choice to begin a business in India by new businesses and organizations with higher development goals. Pvt Ltd company is joined under the Companies Act of 2013, and administered by the Ministry of Corporate Affairs (MCA). It is an enrolled corporate design, that gives business a different legitimate personality from its proprietors. Thus, giving key benefits like the capacity to contract in its own name, and shield individual resources of the proprietors from business liabilities.
                </p>
               
                <h2>Documents Required</h2><br></br>
                <div className="ticks-list left-text">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> PAN Card</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Identity Proof</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Director's Address Proof</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Photograph</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Business Address Proof</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> NOC from owner</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Rent Agreement</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Note: In case of NRI or Foreign National, documents of director (s) must be notarized or apostilled</span> <br></br>
            
                </div>
             
               </div>

            </div>
            <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
               <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <Footer />
 </PrivateLimitedWrapper>
);
}
PrivateLimited.propTypes = {};

PrivateLimited.defaultProps = {};

export default PrivateLimited;
