import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './components/Home/Home'

//Registration
import gst_registration from './components/Registration/GST/GST'
import pf_registration from './components/Registration/PF/PF'
import tan_application from './components/Registration/TAN/TAN'
import pan_application from './components/Registration/PAN/PAN'
import startup_india_registration from './components/Registration/Startup/Startup'
import fssai_registration from './components/Registration/FSSAI/FSSAI'

//Intellectual Property
import trademark_registration from './components/Intellectual_Property/Trademark_registration/Trademark_registration'
import trademark_objection from './components/Intellectual_Property/Trademark_objection/Trademark_objection'
import trademark_opposition from './components/Intellectual_Property/Trademark_opposition/Trademark_opposition'
import trademark_assingment from './components/Intellectual_Property/Trademark_assingment/Trademark_assingment'
import trademark_renewal from './components/Intellectual_Property/Trademark_renewal/Trademark_renewal'


//Change in Business
import llp_to_private_limited from './components/Change_in_Business/llp_to_private_limited/llp_to_private_limited'
import partnership_to_llp from './components/Change_in_Business/partnership_to_llp/partnership_to_llp'
import partnership_to_private_limited from './components/Change_in_Business/partnership_to_private_limited/partnership_to_private_limited'
import private_limited_to_llp from './components/Change_in_Business/private_limited_to_llp/private_limited_to_llp'
import private_limited_to_public from './components/Change_in_Business/private_limited_to_public/private_limited_to_public'
import proprietorship_to_opc from './components/Change_in_Business/Proprietorship_to_opc/Proprietorship_to_opc'

//Business Registration
import llp_company from './components/Business_Registration/llp_company/llp_company'
import micro_finance from './components/Business_Registration/micro_finance/micro_finance'
import nidhi_company from './components/Business_Registration/nidhi_company/nidhi_company'
import opc_registration from './components/Business_Registration/opc_registration/opc_registration'
import partnership_firm from './components/Business_Registration/partnership_firm/partnership_firm'
import private_limited from './components/Business_Registration/private_limited/private_limited'
import producer_company from './components/Business_Registration/producer_company/producer_company'
import section_8_company from './components/Business_Registration/section_8_company/section_8_company'
import sole_proprietorship from './components/Business_Registration/sole_proprietorship/sole_proprietorship'

//Accounting and compliance
import accounting_bookkeeping from './components/Accounting_Compliance/accounting_bookkeeping/accounting_bookkeeping'
import annual_compliances from './components/Accounting_Compliance/annual_compliances/annual_compliances'
import annual_filing from './components/Accounting_Compliance/annual_filing/annual_filing'
import file_dir_3_kyc from './components/Accounting_Compliance/file_dir_3_kyc/file_dir_3_kyc'
import file_inc_20a from './components/Accounting_Compliance/file_inc_20a/file_inc_20a'
import file_inc_22a from './components/Accounting_Compliance/file_inc_22a/file_inc_22a'


//tax filing
import eway_bill from './components/Tax_filing/eway_bill/eway_bill'
import get_return_filing from './components/Tax_filing/get_return_filing/get_return_filing'
import itr_filing from './components/Tax_filing/itr_filing/itr_filing'
import tds_return_filing from './components/Tax_filing/tds_return_filing/tds_return_filing'

function App() {
  return (
    <div className="App">
    <BrowserRouter>
    <Routes>
      <Route exact path='/' Component={Home} />
      <Route exact path='' Component={Home} />
      <Route path='/gst-registration' Component={gst_registration} />
      <Route path='/pf-registration' Component={pf_registration} />
      <Route path='/tan-application' Component={tan_application} />
      <Route path='/startup-india-registration' Component={startup_india_registration} />
      <Route path='/pan-application' Component={pan_application} />
      <Route path='/fssai-registration' Component={fssai_registration} />
    

      <Route path='/trademark-registration' Component={trademark_registration} />
      <Route path='/trademark-objection' Component={trademark_objection} />
      <Route path='/trademark-opposition' Component={trademark_opposition} />
      <Route path='/trademark-assingment' Component={trademark_assingment} />
      <Route path='/trademark-renewal' Component={trademark_renewal} />

      
      <Route path='/llp-to-private-limited' Component={llp_to_private_limited} />
      <Route path='/partnership-to-llp' Component={partnership_to_llp} />
      <Route path='/partnership-to-private-limited' Component={partnership_to_private_limited} />
      <Route path='/private-limited-to-llp' Component={private_limited_to_llp} />
      <Route path='/private-limited-to-public' Component={private_limited_to_public} />
      <Route path='/proprietorship-to-opc' Component={proprietorship_to_opc} />

      <Route path='/llp-company-registration' Component={llp_company} />
      <Route path='/micro-finance-registration' Component={micro_finance} />
      <Route path='/nidhi-company-registration' Component={nidhi_company} />
      <Route path='/opc-registration' Component={opc_registration} />
      <Route path='/partnership-firm-registration' Component={partnership_firm} />
      <Route path='/private-limited-registration' Component={private_limited} />
      <Route path='/producer-company-registration' Component={producer_company} />
      <Route path='/section-8-company-registration' Component={section_8_company} />
      <Route path='/sole-proprietorship-registration' Component={sole_proprietorship} />

      <Route path='/accounting-bookkeeping' Component={accounting_bookkeeping} />
      <Route path='/annual-compliances' Component={annual_compliances} />
      <Route path='/annual-filing' Component={annual_filing} />
      <Route path='/file-dir-3-kyc' Component={file_dir_3_kyc} />
      <Route path='/file-inc-20a' Component={file_inc_20a} />
      <Route path='/file-inc-22a' Component={file_inc_22a} />

      <Route path='/e-way-bill' Component={eway_bill} />
      <Route path='/gst-return-filing' Component={get_return_filing} />
      <Route path='/itr-filing' Component={itr_filing} />
      <Route path='/tds-return-filing' Component={tds_return_filing} />

    </Routes>
    </BrowserRouter>
  </div>
  );
}

export default App;
