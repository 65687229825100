import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TdsReturnFilingWrapper } from './tds_return_filing.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
const TdsReturnFiling = () => 
{
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3001/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"tds return filing",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }
return(
 <TdsReturnFilingWrapper>
    <Header/>
    <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
           
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                 
                  <h4>TDS Return Filing</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">
                An individual who is obligated to deduct charge at source needs to record the TDS return as a component of consistence. A TDS return is put together by Tax Deductor in regard of each quarter with the Income Tax Department. The return is an explanation that involves the subtleties of the duty gathered, wellspring of assortment and expense paid to Government for the revealing time frame.
                </p>
                <p className="blackcolor">
                
                For the most part, charge is deducted on exchanges, for example, Salaries, installment to experts and legally binding premise, installment of rents surpassing specific sum, and so forth For each such sort of exchange and installment, there is recommended pace of interest. The deductee can guarantee the advantage of the expense deducted while ITR recording, provided that the deductor has documented the TDS return on schedule.
                </p>
                <br></br>

               
                <h4>Documents Required</h4><br></br>
                <div className="ticks-list left-text">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> TAN</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Tax Paid Receipt</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Details of tax deducted</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Details of Supplies</span><br></br>
                   
                    </div>
             
                </div>

              </div>
              <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
               <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
 </TdsReturnFilingWrapper>
);
}
TdsReturnFiling.propTypes = {};

TdsReturnFiling.defaultProps = {};

export default TdsReturnFiling;
