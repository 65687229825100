import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PartnershipToPrivateLimitedWrapper } from './partnership_to_private_limited.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
const PartnershipToPrivateLimited = () => 
{
    const [name,setName] = useState()
    const [email,setEmail] = useState()
    const [phone,setPhone] = useState()
    const [message,setMessage] = useState()
  
    const navigate = useNavigate()
    function submit()
    {
      const currentTime = new Date();
      // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  
      axios.post('http://localhost:3001/submit',{
        name:name,
        email:email,
        phone:phone,
        service:"partnership to private limited",
        message:message,
        created_at:formattedTime
      })
      alert('Submitted Successfully')
     // navigate('/accounting-bookkeeping')
    }
return(
 <PartnershipToPrivateLimitedWrapper>
    <Header/>
    <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
          
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
               
                  <h4>Partnership to Private Limited</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">The significant advantage of enlisting a private restricted organization is that it gives a status of a different lawful element which a partnership firm doesn’t have. If there should arise an occurrence of a partnership, an accomplice ‘s individual resources are joined and they would be considered by and by liable for every single obligation or responsibility that the business causes. Consequently, with the development of business, if accomplices have any desire to build its validity and placed restricted responsibility on its individuals, it is better for the accomplices to change over their partnership into a private restricted organization. Despite the fact that legal consistence for a private restricted organization are higher than those of a partnership firm, it offers the organization more chances to thrive and extend its scope.</p>
                <br></br>
                
                <h4>Documents Required</h4><br></br>
                <div className="ticks-list left-text">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> PAN Card</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Identity Proof</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Address Proof</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Photograph</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Business Address Proof</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> NOC form partners</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Rent Agreement</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Verification</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Copy of ITR</span><br></br>
                </div>
                </div>

            </div>
              <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
               <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                    <fieldset>
                                        <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                    </fieldset>
                                    <fieldset>
                                        <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                    </fieldset>
                                    <fieldset>
                                        <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                    </fieldset>
                                </div>
                                <div className="col-lg-6">
                                    <fieldset>
                                        <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                    </fieldset>
                                </div>
                                <div className="col-lg-12">
                                    <fieldset>
                                        <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                    </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
    <Footer/>
 </PartnershipToPrivateLimitedWrapper>
);
}
PartnershipToPrivateLimited.propTypes = {};

PartnershipToPrivateLimited.defaultProps = {};

export default PartnershipToPrivateLimited;
