import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TanWrapper } from './TAN.styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'

const Tan = () => 
{
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [phone,setPhone] = useState()
  const [message,setMessage] = useState()

  const navigate = useNavigate()
  function submit()
  {
    const currentTime = new Date();
    // Format options
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentTime.getDate().toString().padStart(2, '0');
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    axios.post('http://localhost:3001/submit',{
      name:name,
      email:email,
      phone:phone,
      service:"tan",
      message:message,
      created_at:formattedTime
    })
    alert('Submitted Successfully')
   // navigate('/accounting-bookkeeping')
  }
return(
 <TanWrapper>
   <Header />
      <div id="about" className="about section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
           
            <div className="col-lg-8 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
              <div className="about-right-content">
                <div className="section-heading">
                
                  <h4>TAN Application</h4>
                  <div className="line-dec"></div>
                </div>
                <p className="blackcolor">The public authority has carried out a Startup India conspire for the development of organizations by advancing advancements. The DIPP enlistment accompanies many advantages that startups can use by enrolling a business element under the StartUp India plot. This plan is imagined at changing India into a task supplier instead of a task searcher. Just an element which is enlisted as a Private Limited Company or a Limited Liability Partnership (LLP) or a Registered Partnership Firm can get acknowledgment under Startup India Scheme. Further, such startup should be engaged with the exercises towards advancement or improvement in existing items or administrations, and should be equipped for producing business or abundance creation. Just a qualified element according to the standards given by the public authority can take enlistment under the said plot by applying on the web by means of Mobile Application or through entryway set up by DIPP.</p>
                <br></br>
                <h4>Documents Required</h4><br></br>
                <div className="ticks-list left-text">
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Certificate of Incorporation</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> PAN Card</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Constitutional documents</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> List of Directors, DP's and Members</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Social Link</span> <br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Fund related</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> IPR Registration</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Awards/Certificate</span><br></br>
                    <span><img src="assets/images/circle.png" style={{width: '12px'}} /> Annual Statements/ITR</span><br></br>
                </div>
                
                </div>

              </div>
              <div className="col-lg-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
               <div id="myformbox" >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="fill-form">
                                <h4>Get a Quote</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                <fieldset>
                                    <input onChange={(e) => setName(e.target.value)}  type="name" name="name" id="name" placeholder="Full Name" autoComplete="on" required />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setEmail(e.target.value)}  type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Email" required="" />
                                </fieldset>
                                <fieldset>
                                    <input onChange={(e) => setPhone(e.target.value)}  type="text" name="phone" id="phone" pattern="[0-9]*" placeholder="Phone" required="" />
                                </fieldset>
                                </div>
                                <div className="col-lg-6">
                                <fieldset>
                                    <textarea onChange={(e) => setMessage(e.target.value)}  name="message" type="text" className="form-control" id="message" placeholder="Message" required=""></textarea>  
                                </fieldset>
                                </div>
                                <div className="col-lg-12">
                                <fieldset>
                                    <button onClick={submit} type="submit" id="form-submit" className="main-button ">Submit</button>
                                </fieldset>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
 </TanWrapper>
);
}
Tan.propTypes = {};

Tan.defaultProps = {};

export default Tan;
